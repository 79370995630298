:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }
  
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }
  
  blockquote {
    margin: 0 0 1rem;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -.25em;
  }
  
  sup {
    top: -.5em;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
  }
  
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  
  pre,
  code,
  kbd,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
  }
  
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  
  figure {
    margin: 0 0 1rem;
  }
  
  img {
    vertical-align: middle;
    border-style: none;
  }
  
  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  
  
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }
  
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  
  label {
    display: inline-block;
  }
  
  button {
    border-radius: 0;
  }
  
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  input {
    overflow: visible;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  [role="button"] {
    cursor: pointer;
  }
  
  select {
    word-wrap: normal;
  }
  
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  
  textarea {
    overflow: auto;
    resize: vertical;
  }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  
  progress {
    vertical-align: baseline;
  }
  
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  
  output {
    display: inline-block;
  }
  
  summary {
    display: list-item;
    cursor: pointer;
  }
  
  template {
    display: none;
  }
  
  [hidden] {
    display: none !important;
  }
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  
  h1, .h1 {
    font-size: 2.5rem;
  }
  
  h2, .h2 {
    font-size: 2rem;
  }
  
  h3, .h3 {
    font-size: 1.75rem;
  }
  
  h4, .h4 {
    font-size: 1.5rem;
  }
  
  h5, .h5 {
    font-size: 1.25rem;
  }
  
  h6, .h6 {
    font-size: 1rem;
  }
  
.m-0 {
  margin: 0 !important;
}
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline-item {
    display: inline-block;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
  }
  
  .blockquote-footer::before {
    content: "\2014\00A0";
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
  }
  
  .figure {
    display: inline-block;
  }
  
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  
  .figure-caption {
    font-size: 90%;
    color: #6c757d;
  }
  
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
  }
  
  a > code {
    color: inherit;
  }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }
  
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
  
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
  }
  
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }

  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  
  .col-lg-2{
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }
    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }

  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}


.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-baseline {
    vertical-align: baseline !important;
  }

  .p-0 {
    padding: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }

  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  

  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }

  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
@media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none;
    }
  }
  a.badge:hover, a.badge:focus {
    text-decoration: none;
  }
  
.text-light {
    color: #f8f9fa !important;
  }
  
  a.text-light:hover, a.text-light:focus {
    color: #cbd3da !important;
  }
  
.btn .badge {
    position: relative;
    top: -1px;
  }
.badge:empty {
    display: none;
  }
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34;
  }
  
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
.badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130;
  }
  
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
@media print {
    .badge {
      border: 1px solid #000;
    }
    .container {
      min-width: 992px !important;
    }
    
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.text-muted {
  color: #6c757d !important;
}


.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  
@media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  
  .btn:hover {
    color: #212529;
    text-decoration: none;
  }
  
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  
.w-100 {
    width: 100% !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  .text-center {
    text-align: center !important;
  }  
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }


  .pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }

  
.fade {
    transition: opacity 0.15s linear;
  }

  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }

  .breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
.breadcrumb-item {
    display: -ms-flexbox;
    display: flex;
  }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }


  table {
    border-collapse: collapse;
  }
  
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }
  
  .table-bordered {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px;
  }
  
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #b8daff;
  }
  
  .table-primary th,
  .table-primary td,
  .table-primary thead th,
  .table-primary tbody + tbody {
    border-color: #7abaff;
  }
  
  .table-hover .table-primary:hover {
    background-color: #9fcdff;
  }
  
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff;
  }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: #d6d8db;
  }
  
  .table-secondary th,
  .table-secondary td,
  .table-secondary thead th,
  .table-secondary tbody + tbody {
    border-color: #b3b7bb;
  }
  
  .table-hover .table-secondary:hover {
    background-color: #c8cbcf;
  }
  
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf;
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #c3e6cb;
  }
  
  .table-success th,
  .table-success td,
  .table-success thead th,
  .table-success tbody + tbody {
    border-color: #8fd19e;
  }
  
  .table-hover .table-success:hover {
    background-color: #b1dfbb;
  }
  
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb;
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #bee5eb;
  }
  
  .table-info th,
  .table-info td,
  .table-info thead th,
  .table-info tbody + tbody {
    border-color: #86cfda;
  }
  
  .table-hover .table-info:hover {
    background-color: #abdde5;
  }
  
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5;
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #ffeeba;
  }
  
  .table-warning th,
  .table-warning td,
  .table-warning thead th,
  .table-warning tbody + tbody {
    border-color: #ffdf7e;
  }
  
  .table-hover .table-warning:hover {
    background-color: #ffe8a1;
  }
  
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #f5c6cb;
  }
  
  .table-danger th,
  .table-danger td,
  .table-danger thead th,
  .table-danger tbody + tbody {
    border-color: #ed969e;
  }
  
  .table-hover .table-danger:hover {
    background-color: #f1b0b7;
  }
  
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
  }
  
  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: #fdfdfe;
  }
  
  .table-light th,
  .table-light td,
  .table-light thead th,
  .table-light tbody + tbody {
    border-color: #fbfcfc;
  }
  
  .table-hover .table-light:hover {
    background-color: #ececf6;
  }
  
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6;
  }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #c6c8ca;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #95999c;
  }
  
  .table-hover .table-dark:hover {
    background-color: #b9bbbe;
  }
  
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }
  
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .table-dark {
    color: #fff;
    background-color: #343a40;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55;
  }
  
  .table-dark.table-bordered {
    border: 0;
  }
  
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .table-responsive > .table-bordered {
    border: 0;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }
  
.form-group {
    margin-bottom: 1rem;
  }
  
.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
  
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  
textarea.form-control {
    height: auto;
  }
  
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
  }
  
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
  }
  
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
  }
  
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  
  .close:hover {
    color: #000;
    text-decoration: none;
  }
  
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75;
  }
  
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
  
  a.close.disabled {
    pointer-events: none;
  }
  .modal-open {
    overflow: hidden;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
  }
  
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }
  
  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
  }
  
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  
  .modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
  }
  
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }
  
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  
  .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: "";
  }
  
  .modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
  }
  
  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }
  
  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }
  
  .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  
  .modal-backdrop.fade {
    opacity: 0;
  }
  
  .modal-backdrop.show {
    opacity: 0.5;
  }
  
  .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }
  
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }
  
  .modal-footer > * {
    margin: 0.25rem;
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .alert-danger hr {
    border-top-color: #f1b0b7;
  }
  
  .alert-danger .alert-link {
    color: #491217;
  }
  
.badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00;
  }
  
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }    

  
@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
  
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
}