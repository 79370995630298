.header__back {
    background: #313131;
}


.header__maxWidth {
    max-width: 1071px;
    margin: auto;
    padding: 24px 60px 19px;
    box-sizing: content-box;
}

.header__branding {
    display: flex;
    align-items: flex-start;
    padding-top: 0;
}

.header__contestBranding {
    margin-right: 25px;
}
.header__contestBranding img {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

@media (max-width: 767px) {
    .header__maxWidth {
        padding: 12px 12px 20px;
    }
    .header__branding {
        display: block;
        padding: 0;
    }
    .header__contestBranding {
        text-align: center;
        margin-bottom: 10px;
    }
}

.header__headLink {
    font-weight: 800;
    font-size: 18px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    position: relative;
    border: 0;
    background: transparent;
}

.header__headLink_active,
.header__headLink:focus,
.header__headLink:hover {
    color: #ffffff;
    text-decoration: none;
}

.header__headLink_active {
    font-weight: 900 !important;
}

.header__headLink:not(:last-child) {
    margin-right: 29px;
}

@media (max-width: 767px) {
    .header__headLink:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

.header__h1 {
    font-weight: 800;
    font-size: 30px;
    line-height: 42px;
    color: #ffbb6a;
    margin: 0;
}

.header__h1_link {
    font-size: 30px;
    line-height: 42px;
    font-weight: normal;
    margin-bottom: 0 !important;
}

@media (max-width: 767px) {
    .header__h1,
    .header__h1_link {
        font-size: 22px;
        line-height: 30px;
    }
}

.header__statusPill {
    margin-right: 12px;
    margin-bottom: -10px;
}

@media (max-width: 767px) {
    .header__statusPill {
        margin-bottom: 0;
        margin-top: 10px;
    }
}

.header__statusText {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.header__documentLink {
    color: white;
    text-decoration: none;
    border-bottom: 1px dashed #ffffff;
}

.header__documentLink:hover {
    text-decoration: none;
    color: white;
    opacity: 0.7;
}

.header__head {
    margin-bottom: 22px;
}

.header__headLogo {
    text-decoration: none !important;
}

.header__burger {
    display: none;
}

@media (max-width: 940px) {
    .header__head {
        flex-direction: row-reverse;
    }

    .header__mobile {
        order: -1;
    }

    .header__headMenu {
        position: absolute;
        flex-direction: column;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        background: #001a68;
        z-index: 9999;
        display: none;
    }
    .header__burger {
        z-index: 10000;
        position: relative;
        border: 0;
        background: transparent;
        display: flex;
    }
}

.header__headCrumbs {
    margin-bottom: 8px;
}

.header__title {
    /* margin-bottom: 8px; */
}

.header__description {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    padding: 5px 0 7px 0;
    white-space: pre-wrap;
    line-height: 1.7em;
    text-align: justify;
}

.header__mobile_open .header__headMenu {
    display: flex;
}

.header__mobile svg rect {
    transition: 0.3s;
}

.header__mobile_open .header__burger_1 {
    transform: translate(6px, 0px) rotate(45deg);
}

.header__mobile_open .header__burger_2 {
    transform: translate(-65px, 0px);
}

.header__mobile_open .header__burger_3 {
    transform: translate(-8px, 5px) rotate(-45deg);
}

@media (min-width: 941px) {
    .header__headLink_button {
        display: none !important;
    }
}
.headUser_auth_img {
    display: none;
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
}
.header__headMenu_auth a:last-child {
    display: none;
}
@media (max-width: 940px) {
    .header__mobile_open .header__headLink {
        margin-right: 0 !important;
    }
    .headUser_auth {
        display: none;
    }
    .headUser_auth_img {
        display: block;
    }
    .header__headMenu_auth a:last-child {
        display: flex;
    }
}

.header__statusBar {
    color: #ffffff;
    float: right;
    margin: 10px 0 auto auto;
}