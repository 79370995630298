.badgeList {
    margin-bottom: -4px;
}

.badge {
    padding: 6px 27px 7px;
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    border-radius: 20px;
    white-space: nowrap;
    margin-bottom: 4px;
}

.badge:not(:last-child) {
    margin-right: 7px;
}

.badge__red {
    background: #d9534f;
    color: white;
}

.badge__grey {
    background: #90b2c9;
    color: white;
}

.badge__green {
    background: #5cb85c;
    color: white;
}
