.Logo__avatar {
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* background: #90b2c9; */
    margin-right: 19px;
    font-size: 24px;
    color: #ffffff;
}

.Logo__avatar img{
    width: 100%;
}

.Logo__title {
    color: #ffffff;
    font-size: 20px;
    font-weight: bolder;
    line-height: 24px;
}

.Logo__subtitle {
    color: #90b2c9;
    font-size: 14px;
    font-style: italic;
}

.dark .Logo__title {
    color: #ffffff;
}

.dark .Logo__subtitle {
    color: #90b2c9;
}

@media (max-width: 940px) {
    .headerLogoMobile {
        width: fit-content;
        margin: auto;
    }
    .headerLogoMobile .Logo__avatar{
        display: none;
    }
    .headerLogoMobile .Logo__text {
        justify-content: center;
        text-align: center;
    }
    .headerLogoMobile .Logo__title{
        font-size: 13px;
    }
    .headerLogoMobile .Logo__subtitle{
        font-size: 12px;
    }
}