.navigation-side-bar {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.navigation-side-bar a {
    padding: 8px 0;
    color: #ffbb6a;
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    border-radius: 5px;
    margin-left: 12px;
}

.navigation-active {
    color: #ff8b09 !important;
    border-color: #001a68!important;
    font-weight: bold !important;
}

.navigation-side-bar svg {
    width: 30px!important;
    margin-left: 5px;
    margin-right: 10px;
    color: #90b2c9;
}

.navigation-side-bar a:hover {
    color: #90b2c9;
}

.navigation-delimiter {
    color: #ffbb6a;
    padding-top: 25px;
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.navigation-footer {
    color: #90b2c9;
    padding-top: 20px;
    font-size: 13px;
}