.footer__back {
    background: #313131;
    margin-top: auto;
}

.footer__maxWidth {
    max-width: 1071px;
    margin: auto;
    padding: 32px 30px 33px;
    box-sizing: content-box;
    font-size: 14px;
    align-items: center;
}

@media (max-width: 767px) {
    .footer__maxWidth {
        flex-direction: column;
        padding: 12px;
        align-items: flex-start;
    }
}

.footer__col {
    display: flex;
    flex-direction: column;
}

.footer__col:not(:last-child) {
    margin-right: 35px;
}


.footer__colItem {
    color: #ffffff;
    text-decoration: none;
    max-width: 178px;
}

.footer__colItem:not(:last-child) {
    margin-bottom: 4px;
}

.footer__colCopy {
    font-size: 14px;
    margin-left: auto;
    color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 767px) {
    .footer__col:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
    }
    .footer__colCopy{
        margin-left: 0;
    }
}