html,
body,
div#app {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    font-family: "Open Sans", sans-serif;
}

button:focus {
    outline: none;
}

label {
    cursor: pointer;
    margin: 0;
}

/* HACK: react datepicker doesn't have fullwidth mechanism by default */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container > input[type="text"] {
    width: 100%;
}
.ibox {
    clear: both;
    margin-top: 0;
    padding: 0;
}
.ibox.collapsed .ibox-content {
    display: none;
}
.ibox.collapsed .fa.fa-chevron-up:before {
    content: "\f078";
}
.ibox.collapsed .fa.fa-chevron-down:before {
    content: "\f077";
}
.ibox:after,
.ibox:before {
    display: table;
}
.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 15px 7px;
    min-height: 48px;
}
.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}
.ibox-footer {
    color: inherit;
    border-top: 1px solid #e7eaec;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;
}
.ibox-heading {
    background-color: #f3f6fb;
    border-bottom: none;
}
.ibox-heading h3 {
    font-weight: 200;
    font-size: 24px;
}
.ibox-title h5 {
    display: inline-block;
    font-size: 14px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    float: left;
}
.ibox-title .label {
    float: left;
    margin-left: 4px;
}
.ibox-tools {
    display: block;
    float: none;
    margin-top: 0;
    position: relative;
    padding: 0;
    text-align: right;
}
.ibox-tools a {
    cursor: pointer;
    margin-left: 5px;
    color: #c4c4c4;
}
.ibox-tools a.btn-primary {
    color: #fff;
}
.ibox-tools .dropdown-menu > li > a {
    padding: 4px 10px;
    font-size: 12px;
}
.ibox .ibox-tools.open > .dropdown-menu {
    left: auto;
    right: 0;
}

.nav-link
{
    display: inline;
    cursor: pointer;
}
.nav-table .nav-link
{
    display: inline;
    cursor: pointer;
}

.tab-content div.tab-pane
{
    padding: 10px;
}

.admin-navbar
{
    display: inline;
    width : 300px;
}

.admin-content
{
    display: inline;
    width : 100%;
}

.table-sub-component {
    padding: 10px;
}

.input-search, .form-control{
    margin-top: 2px;
    margin-bottom: 2px;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
}

.save-button{
    width: 100%;
    text-align: center;
}