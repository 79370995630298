@keyframes loaderFadeIn {
    0% {
        opacity:0;
    }
    25% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.loader{
    text-align: center;
    width: 100%;
    overflow: hidden;
    animation: fadeIn ease 5s;
}

.loader-spinner {
    display: inline-block;
    text-align: center;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #00b693;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
